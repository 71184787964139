import FeatureLayout from './FeatureLayout';
import React, { useState, useEffect } from 'react';
import LightGallery from 'lightgallery/react';
import lgVideo from 'lightgallery/plugins/video';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Link } from 'react-router-dom';

const HomePage = () => {
  const [imageData, setImageData] = useState([]);
  const [imageData1, setImageData1] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.pravinbhaitogadia.com/Api/get_gallery', {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const allImages = response.data.data;

        const cowImages = allImages.filter(image => image.type === "gallery");
        setImageData(cowImages);
        // setImageData(response.data.data);
        setImageData1(cowImages.slice(0, 6));
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchData();
  }, []);  
  const settings = {
    slidesPerView: 6, // Default slides visible
    slidesPerGroup: 1, // Scroll 1 slide at a time
    autoplay: {
      delay: 1500, // Autoplay speed
      disableOnInteraction: false, // Keeps autoplay on interaction
    },
    loop: true, // Enable continuous loop
    spaceBetween: 20, // Space between slides
    breakpoints: {
      768: {
        slidesPerView: 4, // 4 slides visible on screens <= 768px
      },
      520: {
        slidesPerView: 3, // 3 slides visible on screens <= 520px
      },
    },
  };
  const imagehandler = (url) => {
    if (url && url !== '') {
      window.open(url, '_blank'); 
    } else {
      alert('No URL available for this brand.');
    }
  };
  const Data = [
    { id: 1, category_name: 'Brand One',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://play-lh.googleusercontent.com/pfiWvS7lLXNtmEVJCDX1mxhocyXn3Ri7ifDNamb--QrpF4WRK3Q0yBS-X4qyI7jju2U=w240-h480-rw' },
    { id: 3, category_name: 'Brand Two', url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ8iQYN3SqCb68yqzJYzWyaUStnx43gilRpg&s' },
    { id: 3, category_name: 'Brand Three',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ8iQYN3SqCb68yqzJYzWyaUStnx43gilRpg&s' },
    { id: 4, category_name: 'Brand Four',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2MZf70G6f9pL1uHXnIrSHIWbJAQZ2sr9pIA&s' },
    { id: 5, category_name: 'Brand Five',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR51LAf-mj-WjsLA4Kg24yi0zDENrmB8xZYXA&s' },
    { id: 1, category_name: 'Brand Six',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://play-lh.googleusercontent.com/pfiWvS7lLXNtmEVJCDX1mxhocyXn3Ri7ifDNamb--QrpF4WRK3Q0yBS-X4qyI7jju2U=w240-h480-rw' },
    { id: 4, category_name: 'Brand Seven',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2MZf70G6f9pL1uHXnIrSHIWbJAQZ2sr9pIA&s' },
    { id: 5, category_name: 'Brand Eight',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR51LAf-mj-WjsLA4Kg24yi0zDENrmB8xZYXA&s' },
    { id: 1, category_name: 'Brand Nine',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://play-lh.googleusercontent.com/pfiWvS7lLXNtmEVJCDX1mxhocyXn3Ri7ifDNamb--QrpF4WRK3Q0yBS-X4qyI7jju2U=w240-h480-rw' },
    { id: 3, category_name: 'Brand Ten',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ8iQYN3SqCb68yqzJYzWyaUStnx43gilRpg&s' },
    { id: 4, category_name: 'Brand One',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2MZf70G6f9pL1uHXnIrSHIWbJAQZ2sr9pIA&s' },
    { id: 5, category_name: 'Brand Two',url:'https://play.google.com/store/apps/details?id=com.hindu.hinduhelp&hl=en_IN', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR51LAf-mj-WjsLA4Kg24yi0zDENrmB8xZYXA&s' },
  ];
  return (

    <>
      <FeatureLayout />
      
      <div className="container">
        <h2 className="text-center" style={{ padding: '20px' }}>संगठन</h2>
        <div className="row">
          <div className="col-12">
            <Swiper {...settings}>
              {Data.map((element) => (
                <SwiperSlide key={element.id} className="text-center">
                  {/* <Link to={element.url}> */}
                  <div onClick={()=>imagehandler(element.url)}>
                    <img src={element.logo} alt={element.category_name}
                      style={{
                        width: '200px',
                        height: 'auto',
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                    />
                  </div>

                  {/* </Link> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>


      <div className='container'>
        <div id="rns-head-main" class="">
          <div className="left-sec">
            <h4 id="rns-main-logo" className="mb-0" style={{ color: "#FF8c19" }}>

              Dr Pravinbhai Togadia Gallery
            </h4>

          </div>
          <a href="/gallery-image" id="rns-Viewmore">
            View More</a>
        </div>
        <LightGallery plugins={[lgVideo]} mode="lg-fade">
          {imageData.map((image, index) => (
            <a
              key={index}
              // data-lg-size="200-200" // Set the size of the lightgallery
              className=""
              data-src={image.image}
            // data-sub-html={`<h4>Photo by - <a href='${image.authorUrl}'>${image.author}</a></h4> <p> Location - <a href='${image.locationUrl}'>${image.location}</a></p>`}
            >


            </a>
          ))}

          {imageData1.map((image) => (

            <img

              className="img-responsive img-with-margin"
              src={image.image}
              width="400" // Set the fixed width of the image
              height="400"
            // Set the fixed height of the image
            />
          ))}
        </LightGallery>
      </div>  


      
    </>  
     
  );
};

export default HomePage;
